import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import KonkursImg from "../../assets/images/kon.jpg";
import "../../assets/scss/_profile.scss";
import { MContainer } from "../../element/Elemens";
import {
  fetchContestJoin,
  fetchContestParticipants
} from "../../redux/actions/userActions";

const Konkurs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContestParticipants());
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row) => <div>{row.wrong_way}-o'rin</div>
    },
    {
      name: "Sotuvchilar",
      cell: (row) => <div>{row.last_name}</div>
    },
    {
      name: "Sotgan mahsuloti",
      cell: (row) => (
        <div style={{ fontWeight: "bold" }}>{row.contest_orders} ta</div>
      )
    }
  ];

  const userInfo = useSelector((state) => state.user.userInfo);
  const happeningList = useSelector((state) => state.user.list);

  return (
    // <MContainer>
    //   <div className="competition__page__name_">
    //     Hozircha konkurs tuxtatilgan!
    //   </div>
    // </MContainer>
    <MContainer>
      <div className="competition__page">
        {/* <div>
          <img src={KonkursImg} className="competition__image__container" />
        </div> */}
        <div className="competition__page__body">
          <div className="competition__page__title">
            Uztez.uz jamaosi o’zining mini konkursini e’lon qiladi
          </div>
          <div className="competition__page__text">
            Uztez.uz jamoasi o'z xamkorlarini navbatdagi mini konkursiga start
            beradi{" "}
            <span className="date_time_start">2025 yilning 10 fevral </span>{" "}
            sanasidan. Barcha xamkorlarimiz ushbu konkursda qatnashish
            imkoniyatiga ega. Konkursda qatnashish orqali o'z shaxsiy
            daromadlarini ko'paytirishi va qo'shimchasiga qimmatbaxo sovrinlarni
            qo'lga kiritishlari mumkin. Shartlari esa juda oddiy:  konkurs{" "}
            <span className="date_time_start"> 2025 yilning 10 fevral </span>{" "}
            sanasidan{" "}
            <span className="date_time_end"> 2025 yilning 25 fevral </span>{" "}
            sanasichagacha davom etadi va aynan shu vaqt oralig'ida siz 
            uztez.uz maxsulotlarini maksimal darajada ko'proq sotish orqali eng
            qimmatbaxo so'vrinlarni qo'lga kiritish imkoniyatiga ega bo'lasiz .
            <span className="date_time_hour">
              15 kun mobaynida ko’proq maxsulot soting va sovrinlarni qo’lga
              kiriting.
            </span>
          </div>
          {/* <div className="competition__page__text">
            Aynan siz online savdo ishlarini uztez.uz sayti bilan xamkorlikda
            olib borasizmi? Unday bo’lsa ushbu konkurs aynan siz uchun. Konkurs
            orqali o’z shaxsiy daromadingizni ko’paytiring va qo’shimchasiga
            qimmatbaxo so’vrinlarni qo’lga kiriting. Ushbu super konkurs{" "}
            <span className="date_time_start">8 sentyabrdan</span>{" "}
            <span className="date_time_end">18 noyabrgacha</span> davom etadi.
            Shartlari esa juda oddiy va osson:{" "}
            <span className="date_time_hour">
              70 kun mobaynida ko’proq maxsulot soting va sovrinlarni qo’lga
              kiriting.
            </span>
          </div> */}
          <div className="competition__page__gift">
            <div className="competition__page__gift__title">
              So’vrinlarimiz:
            </div>
            <div className="competition__page__gift__d">
              1-o’ringa – SMART TELEVEZORI -{" "}
              <span className="competition__page__gift__amount">1.000 ta</span>
            </div>
            <div className="competition__page__gift__d">
              2-o’ringa – Chang yutgich va Dazmol -{" "}
              <span className="competition__page__gift__amount">750 ta</span>
            </div>
            <div className="competition__page__gift__d">
              3-o’ringa – Elektiro Pech (Duxovka) -{" "}
              <span className="competition__page__gift__amount">500 ta</span>
            </div>
            <div className="competition__page__gift__d">
              4-o’ringa – Amirsoyga 1 kecha 2 kunga tour paket tour paketga
              nimalar kiradi AVTOTRANSPORT, OVQATLANISh, SHINAM DACHA VA
              HAMKASBLAR DAVRASIDA AJOYIB SUXBAT -{" "}
              <span className="competition__page__gift__amount">350 ta</span>
            </div>
            <div className="competition__page__gift__d">
              5-o’ringa – Dazmol -{" "}
              <span className="competition__page__gift__amount">200 ta</span>
            </div>
            <div className="competition__page__gift__d">
              6-o’ringa – Uztez.uz Internet magazenidan 200,000so'mdan -
              250.000somgacha vaucher (mahsulot saytda ko'ringan narxda
              xisoblanadi ) -{" "}
              <span className="competition__page__gift__amount">150 ta</span>
            </div>
            <div className="competition__page__gift__d">
              7-o’ringa – Elektro Choynak -{" "}
              <span className="competition__page__gift__amount">100 ta</span>
            </div>
          </div>
          <div className="competition__page__footer">
            Konkurs 25 - fevral kunigacha davom etadi!
          </div>
          <div className="competition__page__footer">
            Konkurs tugaganidan so’ng sov’galar 15 kun ichida o’z egalariga
            topshiriladi!
          </div>
          {/* <div className="competition__page__footer">
            Xar bir sotilgan mahsulot konkursning dinariga qo'shilib boriladi.
            Konkurs davrida yig'ilgan dinarlar tugatilgandan so'ng ayirib
            yuboriladi
          </div> */}
        </div>
        {userInfo.attending_contest ? (
          ""
        ) : (
          <div
            className="competition__page__footer__button"
            onClick={() => {
              dispatch(fetchContestJoin());
            }}
          >
            Konkursga start berish
          </div>
        )}

        <div>
          <DataTable
            columns={columns}
            data={happeningList}
            responsive
            noHeader
            defaultSortAsc={false}
          />
        </div>
      </div>
    </MContainer>
  );
};

export default Konkurs;
